<template>
  <action-dispatcher action="professional/getHome">
    <div class="views-home">
      <div
        v-if="bannersHome && bannersHome.length"
        :style="`background-image: url(${bannersHome[0].banner_image})`"
        class="banner"
      ></div>
      <div class="container pt-5">
        <div class="row justify-content-center d-none d-md-flex">
          <div
            @click="$router.push({ name: 'professionals' })"
            class="col-md-6 col-lg-3 mb-5 d-flex"
          >
            <initial-card
              alt="Profissionais"
              img="professionals"
              title="Profissionais"
              text="Conheça os profissionais certificados especialistas em diversas
                áreas."
            />
          </div>
          <div @click="checkOpportunity" class="col-md-6 col-lg-3 mb-5 d-flex ">
            <initial-card
              alt="Oportunidades"
              img="opportunities"
              title="OPORTUNIDADES "
              text="Encontre o projeto de uma empresa parceira para começar  a executar."
            />
          </div>
          <div
            @click="$router.push({ name: 'providers' })"
            class="col-md-6 col-lg-3 d-flex"
          >
            <initial-card
              alt="Fornecedores"
              img="providers"
              title="FORNECEDORES"
              text="Encontre os nossos parceiros com ofertas únicas para o seu projeto."
            />
          </div>
          <div @click="checkProjects" class="col-md-6 col-lg-3 d-flex">
            <initial-card
              alt="publicar oportunidade"
              img="projects"
              title="PUBLICAR OPORTUNIDADES"
              text="Encontre o profissional ideal para trabalhar em seus projetos."
            />
          </div>
        </div>
        <div class="d-flex align-items-center cards-mobile d-md-none mb-5">
          <div @click="$router.push({ name: 'professionals' })">
            <initial-card
              alt="Profissionais"
              img="professionals"
              title="Profissionais"
              text="Conheça os profissionais certificados especialistas em diversas
                áreas."
            />
          </div>
          <div class="mx-3" @click="checkOpportunity">
            <initial-card
              alt="Oportunidades"
              img="opportunities"
              title="OPORTUNIDADES "
              text="Encontre o projeto de uma empresa parceira para começar  a executar."
            />
          </div>
          <div class="mr-3" @click="$router.push({ name: 'providers' })">
            <initial-card
              alt="Fornecedores"
              img="providers"
              title="FORNECEDORES"
              text="Encontre os nossos parceiros com ofertas únicas para o seu projeto."
            />
          </div>
          <div @click="checkProjects">
            <initial-card
              alt="publicar oportunidade"
              img="projects"
              title="PUBLICAR OPORTUNIDADES"
              text="Encontre o profissional ideal para trabalhar em seus projetos."
            />
          </div>
        </div>
        <home-carousel
          v-if="professionals.length"
          name="Profissionais"
          :professionals="professionals"
          class="mb-5"
        />
        <home-carousel
          v-if="providers.length"
          name="Fornecedores"
          class="mb-5"
          :professionals="providers"
        />
        <div class="d-lg-none my-5 py-5"></div>
        <responsive-modal
          :hideHeader="false"
          v-model="certificationModal"
          centered
        >
          <certification-modal
            title="Formação em Projetos e Obras BORAnaOBRA"
            text1="Somente certificados na"
            text2="podem ver oportunidades."
            buttonCopy="Quero ser certificado"
            buttonLink="https://google.com"
            type="certificate"
          />
        </responsive-modal>
        <responsive-modal :hideHeader="false" v-model="newUserModal" centered>
          <certification-modal
            title="TER ACESSO A TODOS OS BENEFÍCIOS"
            text1="Faça login ou cadastre-se no Bora Conecta para"
            text2="da plataforma."
            buttonCopy="Entrar"
            buttonLink="login"
            type="login"
          />
        </responsive-modal>
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import { paramsMixin } from "@/mixins";
import { mapState } from "vuex";
import InitialCard from "@/components/home/InitialCard.vue";
import CertificationModal from "@/components/home/CertificationModal.vue";
import HomeCarousel from "@/components/home/HomeCarousel.vue";
// import CardProviders from "@/components/providers/CardProviders.vue"

export default {
  mixins: [paramsMixin],
  components: { InitialCard, CertificationModal, HomeCarousel },
  data() {
    return {
      shape: "large",
      certificationModal: false,
      newUserModal: false
    };
  },
  computed: {
    ...mapState("opportunity", ["my_opportunities"]),
    ...mapState("professional", ["professionals"]),
    ...mapState("banners", ["bannersHome"]),
    ...mapState("providers", ["providers"]),
    isCertified() {
      return (
        this.$user.profile &&
        this.$user.profile.emblem &&
        this.$user.profile.emblem.is_active
      );
    }
  },
  methods: {
    checkScreen() {
      if (window.screen.width > 992 && window.screen.width < 1200) {
        return (this.shape = "medium");
      } else {
        return (this.shape = "large");
      }
    },
    checkOpportunity() {
      if (this.$user.user) {
        if (this.$user.profile.emblem) {
          this.$router.push({ name: "opportunities" });
        } else {
          this.certificationModal = true;
        }
      } else {
        this.newUserModal = true;
      }
    },
    checkProjects() {
      if (this.$user.user) {
        this.$router.push({ name: "new-opportunity" });
      } else {
        this.newUserModal = true;
      }
    }
  },
  created() {
    if (this.$user.user) {
      this.$store.dispatch("opportunity/getMy", {
        id: this.$user.user.id,
        params: this.paramsFilter
      });
    }

    this.checkScreen();
    // this.$store.dispatch("professional/getHome").then(() => {});
    this.$store.dispatch("banners/getHome").then();
    this.$store.dispatch("providers/getProvidersHome").then();
  }
};
</script>
<style lang="scss" scoped>
.views-home {
  .cards-mobile {
    overflow-x: scroll !important;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  h3 {
    @media screen and (max-width: 768px) {
      font-size: 16px !important;
    }
  }
  p {
    color: #000;
    display: flex;
    align-items: center;
    i {
      font-size: 25px;
      color: 000;
      font-weight: 500;
      margin-right: 0.5rem;
    }
  }

  .banner {
    //background-image: url(../assets/images/home-banner-test.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 0;
    padding-bottom: 30%;
    /* @media screen and (max-width: 767px) {
      padding-bottom: 80%;
    } */
  }
}
</style>
