<template>
  <div class="card-initial-component-home">
    <div
      class="card-custom-header d-flex align-items-center justify-content-center"
    >
      <img
        width="200"
        height="200"
        :src="images[img]"
        :alt="alt"
        rel="preload"
        class="d-none d-md-block"
      />
      <img
        width="120"
        height="120"
        :src="images[img]"
        :alt="alt"
        rel="preload"
        class="d-md-none"
      />
    </div>
    <div class="card-custom-body p-3">
      <h3 class="text-uppercase text-md-left text-center">{{ title }}</h3>
      <p class="d-none d-md-block">{{ text }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    img: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      images: {
        professionals: require("@/assets/images/home/professionals.png"),
        opportunities: require("@/assets/images/home/opportunities.png"),
        projects: require("@/assets/images/home/projects.png"),
        providers: require("@/assets/images/home/providers.png")
      }
    };
  }
};
</script>
<style scoped lang="scss">
.card-initial-component-home {
  &:hover {
    cursor: pointer;
  }
  .card-custom-header {
    background: #1b1d20;
    border-radius: 16px 16px 0px 0px;
    height: 233px;
    @media screen and (max-width: 767px) {
      height: auto;
    }
  }

  .card-custom-body {
    background: #0f1114;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 16px 16px;
    height: 120px;
    h3 {
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #fbfbfb;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #f5f5f5;
    }
    @media screen and (max-width: 767px) {
      height: 60px;
      width: 150px;
      h3 {
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}
</style>
