<template>
  <div class="home-carousel-component position-relative mr-4">
    <router-link
      :to="{ name: routeByName }"
      class="text-uppercase btn d-flex align-items-center mb-3"
      >{{ name }} <i class="ml-2 icon-arrow-right"></i>
    </router-link>
    <div class="shadow"></div>
    <carousel
      navigationNextLabel="<i class='icon-arrow-right'/>"
      navigationPrevLabel="<i class='icon-arrow-left'></i>"
      :navigationEnabled="true"
      :perPageCustom="pages"
      :autoplay="false"
      :paginationEnabled="false"
    >
      <slide
        class="d-flex"
        v-for="(i, index) in professionals"
        :key="`professional-${index}`"
      >
        <div v-if="name == 'Profissionais'" class="d-flex">
          <professional-card class="mx-lg-3 mx-md-3 mx-4" :professional="i" />
        </div>
        <div class="d-flex" v-else>
          <card-providers :provider="i" class="mx-lg-3 mx-md-3 mx-4" />
        </div>
      </slide>
      <slide>
        <button
          class="btn btn-carousel text-white h-100"
          @click="goToRouter(name)"
        >
          VER MAIS
        </button>
      </slide>
    </carousel>
  </div>
</template>
<script>
import ProfessionalCard from "@/components/professional/ProfessionalCard.vue";
import CardProviders from "@/components/providers/CardProviders.vue";
export default {
  components: { ProfessionalCard, CardProviders },
  props: {
    name: {
      name: {
        type: String,
        required: true
      }
    },
    professionals: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      pages: [
        [320, 1],
        [767, 2],
        [991, 3.2],
        [1350, 4.1]
      ]
    };
  },
  methods: {
    goToRouter(name) {
      if (name === "Profissionais") {
        this.$router.push({ name: "professionals" });
      } else if (name === "Fornecedores") {
        this.$router.push({ name: "providers" });
      }
    }
  },
  computed: {
    routeByName() {
      if (this.name == "Profissionais") {
        return "professionals";
      } else {
        return "providers";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.home-carousel-component {
  .btn {
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.015em;
    text-transform: uppercase;
    color: #ffffff;
    i {
      font-size: 22px;
    }
  }

  .btn-carousel {
    background: #1b1d20;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.015em;
    text-align: left;
    color: #ffcc00 !important;
    transition: 0.4s;
    &:hover {
      filter: brightness(70%);
    }
  }

  .shadow {
    background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    width: 35px;
    height: 98%;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-48%);
    z-index: 8;
  }
}
::v-deep .VueCarousel-navigation-button {
  width: 45px;
  height: 45px;
  border-radius: 50%;

  i {
    font-size: 30px;
    color: #fff;
  }
  /* @media screen and (max-width: 768px) {
    display: none;
  } */
}
::v-deep .VueCarousel-navigation-prev {
  @media screen and (min-width: 991px) {
    position: absolute;
    left: 10px;
  }
  @media screen and (max-width: 768px) {
    margin-left: 20px;
  }
  &.VueCarousel-navigation--disabled {
    display: none;
  }
}
::v-deep .VueCarousel-navigation-next {
  /* background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 100%); */
  @media screen and (min-width: 991px) {
    position: absolute;
    right: 25px;
    z-index: 10;
  }
  margin-right: 15px;

  &.VueCarousel-navigation--disabled {
    display: none;
  }
}
</style>
